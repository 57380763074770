import React, { useState, useEffect, useRef } from 'react'
import ZoomAnimator from './ZoomAnimator'

function AnimatorLayout(props) {
	const [curChild, setCurChild] = useState(props.children)
	const [curLocation, setCurLocation] = useState(props.uniqKey)
	const [prevChild, setPrevChild] = useState(null)
	const [prevLocation, setPrevLocation] = useState(null)

	const prevRef = useRef(props)

	useEffect(() => {
		setCurChild(props.children)
		setCurLocation(props.uniqKey)
		setPrevChild(prevRef.current.children)
		setPrevLocation(prevRef.current.uniqKey)
		prevRef.current = props
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.uniqKey])

	return (
		<ZoomAnimator
			prevChild={prevChild}
			curChild={curChild}
			prevLocation={prevLocation}
			curLocation={curLocation}
			location={props.uniqKey}
			animationCallback={() => {
				setPrevChild(null)
			}}></ZoomAnimator>
	)
}

export default AnimatorLayout
