import React from 'react'
import './styles.scss'

function ImageBackground({ style, backgroundImage, children }) {
	return (
		<div className='image-background' styles={style}>
			<img className='background-image' src={backgroundImage} alt='' />
			<div className='content'>{children}</div>
		</div>
	)
}

export default ImageBackground
