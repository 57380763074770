import React, { useState, useRef, useEffect } from 'react'
import {
  Button,
  Popup,
  ImageBackground,
  CustomScrollContent
} from '../../components'
import { STAGING_URL } from '../../Config'
import './styles.scss'
import { withTranslation, language } from 'react-i18next'
import SlideShow from 'react-image-show'
import { useHistory } from 'react-router-dom'

import double_sticker from '../../images/double_sticker.png'
import bg from '../../images/bg_sales_zone.jpg'

import blue_magnet_on from '../../images/TestingZone/blue_magnet_on.png'
import orange_magnet_on from '../../images/TestingZone/orange_magnet_on.png'
import blue_magnet_off from '../../images/TestingZone/blue_magnet_off.png'
import orange_magnet_off from '../../images/TestingZone/orange_magnet_off.png'

import button_buy_now_glow from '../../images/SalesZone/button_buy_now_glow.png'
import button_share_glow from '../../images/SalesZone/button_share_glow.png'
import button_get_samples_glow from '../../images/SalesZone/button_get_samples_glow.png'

import button_buy_now_glow_th from '../../images/SalesZone/button_buy_now_glow.png'
import button_share_glow_th from '../../images/SalesZone/button_share_glow.png'
import button_get_samples_glow_th from '../../images/SalesZone/button_get_samples_glow.png'

import button_buy_now_glow_kr from '../../images/SalesZone/button_buy_now_glow.png'
import button_share_glow_kr from '../../images/SalesZone/button_share_glow.png'
import button_get_samples_glow_kr from '../../images/SalesZone/button_get_samples_glow.png'

import buy_now from '../../images/buy_now_icon.png'
import share_icon from '../../images/share_icon.png'
import right_arrow from '../../images/right-arrow.svg'

import oily_slider_0 from '../../images/SalesZone/oily_slider_0.jpg'
import oily_slider_1 from '../../images/SalesZone/oily_slider_1.jpg'
import oily_slider_2 from '../../images/SalesZone/oily_slider_2.jpg'
import oily_slider_3 from '../../images/SalesZone/oily_slider_3.jpg'
import oily_slider_4 from '../../images/SalesZone/oily_slider_4.jpg'
import oily_slider_5 from '../../images/SalesZone/oily_slider_5.jpg'
import oily_slider_6 from '../../images/SalesZone/oily_slider_6.jpg'
import dry_slider_0 from '../../images/SalesZone/dry_slider_0.jpg'
import dry_slider_1 from '../../images/SalesZone/dry_slider_1.jpg'
import dry_slider_2 from '../../images/SalesZone/dry_slider_2.jpg'
import dry_slider_3 from '../../images/SalesZone/dry_slider_3.jpg'
import dry_slider_4 from '../../images/SalesZone/dry_slider_4.jpg'
import dry_slider_5 from '../../images/SalesZone/dry_slider_5.jpg'
import dry_slider_6 from '../../images/SalesZone/dry_slider_6.jpg'
import { GetSamples, SharePopup, LeavingSoSoon } from '../../containers'

let arrayButton = {
  en: [button_buy_now_glow, button_share_glow, button_get_samples_glow],
  ms: [button_buy_now_glow, button_share_glow, button_get_samples_glow],
  sg: [button_buy_now_glow, button_share_glow, button_get_samples_glow],
  th: [
    button_buy_now_glow_th,
    button_share_glow_th,
    button_get_samples_glow_th
  ],
  kr: [button_buy_now_glow_kr, button_share_glow_kr, button_get_samples_glow_kr]
}

const NONE = 0
const PRODUCT_INFO = 1
// const SHARE_POP_UP = 2
const SHARE_MAGNETIC_EXPERIENCE = 3
const GET_SAMPLES = 4
const SHARE_ALL_GOOD_THING = 5
const SHOW_SCOREBOARD = 6
const LEAVING_SO_SOON = 7

function SalesZone({ t, i18n }) {
  const history = useHistory()
  const [openPopup, setOpenPopup] = useState(NONE)
  const [productInfoIndex, setProductInfoIndex] = useState(0)
  const [productsInfo, setProductsInfo] = useState([
    {
      index: 0,
      largeImage: oily_slider_0,
      images: [
        oily_slider_0,
        oily_slider_1,
        oily_slider_2,
        oily_slider_3,
        oily_slider_4,
        oily_slider_5,
        oily_slider_6
      ],
      title: t('for_oily_and_reactive_skin'),
      step1: t('step_1_oily_make_up_remover'),
      step2: t('step_2_oily_gel_cleanser')
    },
    {
      index: 1,
      largeImage: dry_slider_0,
      images: [
        dry_slider_0,
        dry_slider_1,
        dry_slider_2,
        dry_slider_3,
        dry_slider_4,
        dry_slider_5,
        dry_slider_6
      ],
      title: t('for_dry_and_reactive_skin'),
      step1: t('step_1_dry_make_up_remover'),
      step2: t('step_2_dry_gel_cleanser')
    }
  ])
  const [shareBackCode, setShareBackCode] = useState(NONE)

  const clickButton = (code) => {
    setProductInfoIndex(code - 1)
    if (code === 1) {
      setOpenPopup(PRODUCT_INFO)
    }
    if (code === 2) {
      setOpenPopup(PRODUCT_INFO)
      // setOpenPopup(SHOW_SCOREBOARD)
    }
  }

  const onClickGlowButton = (code) => {
    if (code === 0) {
      // click buy now
      window.open(t('buy_now_url'), '_blank')
    }
    if (code === 1) {
      //click share
      setOpenPopup(SHARE_MAGNETIC_EXPERIENCE)
    }
    if (code === 2) {
      //click get sample
      setOpenPopup(GET_SAMPLES)
    }
  }
  function breakLine(text) {
    var br = React.createElement('br')
    var regex = /(<br\/>)/g
    return text.split(regex).map(function (line, index) {
      return line.match(regex) ? <br key={'key_' + index} /> : line
    })
  }

  const shareTap = () => {
    setShareBackCode(openPopup)
    setOpenPopup(SHARE_ALL_GOOD_THING)
    // setProductInfoIndex(0)
  }
  const getSampleTap = () => {
    setOpenPopup(GET_SAMPLES)
    setProductInfoIndex(0)
  }
  const buyNowTap = () => {
    window.open(t('buy_now_url'), '_blank')
  }
  const backToLabTap = () => {
    setOpenPopup(NONE)
    setProductInfoIndex(0)
  }
  //   console.log('i18n.language ', i18n.language)
  const renderProductInfoItem = (item) => {
    // let imgWidth = (599 * size.width) / 1920
    // let imgHeight = (480 * size.width) / 1920
    // let thumbWidth = (63 * size.width) / 1920
    // let thumbHeight = (79 * size.width) / 1920

    return (
      <div key={`${item.index}`} className='product-info-content'>
        <div className='left-content'>
          <SlideShow
            images={item.images}
            width='25vw;'
            imagesWidth='25vw;'
            imagesHeight='31.197916666666664vw'
            imagesHeightMobile='31.197916666666664vw'
            thumbnailsHeight='4.114583333333333vw;'
            indicators={false}
            thumbnails={true}
            fixedImagesHeight
            // infinite
            arrows={false}
          />
        </div>
        <div className='right-content'>
          <div className='section1'>
            <div
              dangerouslySetInnerHTML={{ __html: item.title }}
              className='bold-text text-center'
            />
            <div className='step-group'>
              <div className='col'>
                <div className='bold-text step-text text-center'>
                  {t('step_1')}
                </div>
                <div className='bold-text text-center step-text2'>
                  {breakLine(item.step1)}
                </div>
                <div className='hash-tag text-center'>
                  {t('water_without_cotton')}
                </div>
                <div className='head-title text-center step-text3'>
                  {t('make_up_remove')}
                </div>
              </div>
              <div className='separator-line' />
              <div className='col'>
                <div className='bold-text step-text text-center'>
                  {t('step_2')}
                </div>
                <div className='bold-text text-center step-text2'>
                  {breakLine(item.step2)}
                </div>
                <div className='hash-tag text-center'>
                  {t('magnetic_gel_cleanser')}
                </div>
                <div className='head-title text-center step-text3'>
                  {t('cleanse_without_irritation')}
                </div>
              </div>
            </div>
          </div>
          <div className='section2'>
            <div className='row mb14'>
              <div
                className='main-button button mr18'
                style={{ justifyContent: 'space-between' }}
                onClick={() => buyNowTap()}
              >
                <div className='row'>
                  <img className='button-icon' src={buy_now} alt='' />
                  <div className='button-title'>{t('buy_now')}</div>
                </div>
                <img className='button-icon' src={right_arrow} alt='' />
              </div>
              <Button
                onClick={() => getSampleTap()}
                className={'button'}
                showRightIcon={true}
                title={t('get_samples')}
              />
            </div>
            <div className='row'>
              <div
                className='main-button button mr18'
                style={{ justifyContent: 'space-between' }}
                onClick={() => shareTap()}
              >
                <div className='row'>
                  <img className='button-icon' src={share_icon} alt='' />
                  <div className='button-title'>{t('share')}</div>
                </div>
                <img className='button-icon' src={right_arrow} alt='' />
              </div>
              <Button
                onClick={() => backToLabTap()}
                className={'button'}
                showRightIcon={true}
                title={t('back_to_lab')}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
  const renderProductInfo = () => {
    return (
      <Popup
        isOpen={openPopup === PRODUCT_INFO}
        containerStyle={{
          backgroundColor:
            productInfoIndex === 0
              ? 'rgba(255, 105, 0, 0.95)'
              : 'rgba(0, 114, 206, 0.95)',
          padding: '3% 2% 3% 2%'
        }}
        showCloseButton={true}
        onClose={() => {
          setOpenPopup(NONE)
          setProductInfoIndex(0)
        }}
      >
        <CustomScrollContent
          defaultIndex={productInfoIndex}
          className='product-info-wrapper'
          // forUpdate={stickerButtonClick}
          data={productsInfo}
          numberOfContent={productsInfo.length}
          onSlideIndexChange={(index) => {
            setProductInfoIndex(index)
          }}
          renderContent={(item) => {
            return renderProductInfoItem(item)
          }}
        />
      </Popup>
    )
  }

  const renderScoreboard = () => {
    return (
      <iframe
        style={{ zIndex: 10 }}
        id='iframe_scoreboard'
        className={openPopup === SHOW_SCOREBOARD ? 'show' : ''}
        title='scoreboard'
        width='100%'
        height='100%'
        onLoad={() => {
          const iframe = document.getElementById('iframe_scoreboard')
          const innerDoc =
            iframe.contentDocument || iframe.contentWindow.document
          const close_button = innerDoc.querySelector('a.close-button')
          close_button.addEventListener('click', function () {
            iframe.classList.remove('show')
          })
        }}
        src='../Scoreboard/index.html'
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      />
    )
  }

  return (
    <div className='sales-zone'>
      <ImageBackground backgroundImage={bg}>
        <div className='blue-magnet-on-click' onClick={() => clickButton(2)} />
        <img className='icon blue-magnet-on' src={blue_magnet_on} alt='' />
        <img className='icon blue-magnet-off' src={blue_magnet_off} alt='' />
        <div
          className='orange-magnet-on-click'
          onClick={() => clickButton(1)}
        />
        <img className='icon orange-magnet-on' src={orange_magnet_on} alt='' />
        <img
          className='icon orange-magnet-off'
          src={orange_magnet_off}
          alt=''
        />

        <div
          className='trophy-button-overlay'
          onClick={() => {
            const iframe = document.getElementById('iframe_scoreboard')
            iframe.classList.add('show')
          }}
        />
        <div className='glow-button-group'>
          {arrayButton[i18n.language] &&
            arrayButton[i18n.language].map((item, index) => {
              return (
                <img
                  key={index}
                  className='glow-button cursor'
                  src={item}
                  alt=''
                  onClick={() => onClickGlowButton(index)}
                />
              )
            })}
        </div>

        <div className='direction-group'>
          <div className='normal-text title'>{t('click_and_move')}</div>
          <div
            className='pre-page'
            onClick={() => {
              history.push('/testing-zone')
            }}
          />
        </div>
        {renderProductInfo()}
        {renderScoreboard()}
        <SharePopup
          type={'share-the-experience'}
          shareUrl={STAGING_URL}
          show={openPopup === SHARE_MAGNETIC_EXPERIENCE}
          onClose={() => {
            setOpenPopup(NONE)
          }}
          t={t}
          hiddenBackButton={true}
          onClick={() => {}}
        />
        <SharePopup
          type={'share-all-good-thing'}
          shareUrl={STAGING_URL}
          show={openPopup === SHARE_ALL_GOOD_THING}
          onClose={() => {
            setOpenPopup(NONE)
          }}
          t={t}
          onClick={() => {}}
          onBack={() => {
            setOpenPopup(shareBackCode)
          }}
        />
        <GetSamples
          t={t}
          show={openPopup === GET_SAMPLES}
          onClose={() => {
            setOpenPopup(NONE)
          }}
          onClick={() => {}}
        />
      </ImageBackground>
    </div>
  )
}

export default withTranslation()(SalesZone)
