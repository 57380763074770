/**
 * Jack Dao
 */
import React from 'react'
import './ProductBenefit.scss'
import { Button, Popup } from '../../components'

import magnetic_cleansing_icon from '../../images/TestingZone/magnetic_cleansing_icon.png'
import product_benefit_1 from '../../images/TestingZone/product_benefit_1.png'
import product_benefit_2 from '../../images/TestingZone/product_benefit_2.png'
import product_benefit_3 from '../../images/TestingZone/product_benefit_3.png'
import product_benefit_4 from '../../images/TestingZone/product_benefit_4.png'

import right_arrow from '../../images/right-arrow.svg'
import watch_video_icon from '../../images/watch_video_icon.png'
import share_icon from '../../images/share_icon.png'

const ProductBenefit = ({
  t,
  show,
  onClose,
  onClick,
  showShareButton,
  onShareClick
}) => {
  function breakLine(text) {
    var br = React.createElement('br')
    var regex = /(<br\/>)/g
    return text.split(regex).map(function (line, index) {
      return line.match(regex) ? <br key={'key_' + index} /> : line
    })
  }
  const renderButton = (icon, title, code, className = null) => {
    return (
      <div
        className={`main-button button ${className ? className : ''}`}
        onClick={() => {
          if (onClick) {
            onClick(code)
          }
        }}
      >
        <div className='row'>
          {icon && <img className='img-icon' src={icon} alt='' />}
          <div className='button-title text-center bold-text'>{title}</div>
        </div>
        <img className='button-icon' src={right_arrow} alt='' />
      </div>
    )
  }
  const renderProductBenefit = ({
    icon,
    title,
    description,
    className = null
  }) => {
    return (
      <div className={`product-benefit-hover`}>
        <img className='img-icon' src={icon} alt='' />
        <div className='content-hover'>
          <div className='head-title'>{title}</div>
          <div className={`description ${className ? className : ''}`}>
            {description}
          </div>
        </div>
      </div>
    )
  }
  return (
    <Popup
      customClass='product-benefit-popup'
      containerStyle={{ padding: '2% 2% 4% 2%' }}
      isOpen={show}
      onClose={onClose}
      showShareButton={showShareButton}
      onShareClick={onShareClick}
    >
      <div className='product-benefit'>
        <div className=''>
          <div className='header'>
            <div className='head-title'>{breakLine(t('the_benefits_of'))}</div>
            <div className='bold-text'>{t('magnetic_cleansing')}</div>
          </div>
          <div className='section1'>
            <div className='video-product-benefit'>
              <iframe
                title='video'
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/jL6UtVaFYRs?autoplay=1'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen='allowFullScreen'
                //   mozallowfullscreen='mozallowfullscreen'
                //   msallowfullscreen='msallowfullscreen'
                //   oallowfullscreen='oallowfullscreen'
              />
            </div>
            <div className='content-benefit'>
              {renderProductBenefit({
                icon: product_benefit_1,
                title: t('cleaning_efficacy'),
                description: breakLine(t('cleaning_efficacy_content')),
                className: 'cleaning_efficacy'
              })}
              {renderProductBenefit({
                icon: product_benefit_2,
                title: t('reactive_skin'),
                description: breakLine(t('reactive_skin_content')),
                className: 'reactive_skin'
              })}
              {renderProductBenefit({
                icon: product_benefit_3,
                title: t('harsh_free_and_gentle'),
                description: breakLine(t('harsh_free_and_gentle_content')),
                className: 'harsh_free_and_gentle'
              })}
              {renderProductBenefit({
                icon: product_benefit_4,
                title: t('moisture_balance'),
                description: breakLine(t('moisture_balance_content')),
                className: 'moisture_balance'
              })}
            </div>
          </div>
        </div>
        <div className='section2'>
          {renderButton(share_icon, t('share'), 'share', 'ml38')}
          {renderButton(null, t('get_samples'), 'get_samples', 'ml38')}
          {renderButton(null, t('back'), 'back', 'ml38')}
        </div>
      </div>
    </Popup>
  )
}

export default ProductBenefit
