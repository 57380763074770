import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const LANG = ['', 'en', 'kr', 'th', 'sg', 'ms']

function DefaultLayout({ component: Component, t, i18n, ...props }) {
  const { lang } = props.computedMatch.params

  useEffect(() => {
    if (LANG.includes(lang) || typeof lang == 'undefined') {
      i18n.changeLanguage(lang)
    } else {
      window.location.href = '/'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Route
      {...props}
      render={(matchProps) => <Component {...matchProps} t={t} i18n={i18n} />}
    />
  )
}

export default withTranslation()(DefaultLayout)
