/**
 * Jack Dao
 */
import React, { useState, useEffect } from 'react'
import './styles.scss'
import './animate.min.css'
import { Animated } from 'react-animated-css'
import right_arrow from '../../images/pop_up_right_arrow.png'

const CustomScrollContent = ({
  className,
  renderContent,
  data,
  onSlideIndexChange,
  defaultIndex = 0,
  hiddenPreviewButton = false
}) => {
  const [contents, setContents] = useState(data)
  const [oldIndex, setOldIndex] = useState(defaultIndex)
  const [currentIndex, setCurrentIndex] = useState(defaultIndex)
  const [isAnimation, setIsAnimation] = useState(false)
  useEffect(() => {
    setContents(data)
    return function cleanup() {
      // keyboardDidShowListener.remove()
    }
  }, [data])

  const onClickNextButton = () => {
    if (!isAnimation) {
      setIsAnimation(true)
    }
    if (currentIndex < contents.length - 1) {
      setOldIndex(currentIndex)
      let nextIndex = currentIndex + 1
      setCurrentIndex(nextIndex)
      if (onSlideIndexChange) {
        onSlideIndexChange(nextIndex)
      }
    }
  }

  const onClickPreButton = () => {
    if (!isAnimation) {
      setIsAnimation(true)
    }
    if (currentIndex > 0) {
      setOldIndex(currentIndex)
      let nextIndex = currentIndex - 1
      setCurrentIndex(nextIndex)
      if (onSlideIndexChange) {
        onSlideIndexChange(nextIndex)
      }
    }
  }

  return (
    <div class={`${className}`}>
      <div className='button-left'>
        {!hiddenPreviewButton && currentIndex > 0 && (
          <img
            className='button-left opposite cursor'
            src={right_arrow}
            alt=''
            onClick={() => onClickPreButton()}
          />
        )}
      </div>
      <div className='wrapper-animation'>
        {contents &&
          contents.length > 0 &&
          contents.map((item, index) => {
            if (currentIndex !== index) {
              return null
            }
            return (
              <Animated
                key={`${index}`}
                animationIn={
                  oldIndex > currentIndex ? 'fadeInLeft' : 'fadeInRight'
                }
                animationOut={
                  oldIndex > currentIndex ? 'fadeOutRight' : 'fadeOutLeft'
                }
                animationInDuration={500}
                animationOutDuration={500}
                animateOnMount={isAnimation}
                isVisible={currentIndex === index}
              >
                {renderContent(item)}
              </Animated>
            )
          })}
      </div>
      <div className='button-right'>
        {currentIndex < contents.length - 1 && (
          <img
            className='button-right cursor'
            src={right_arrow}
            alt=''
            onClick={() => onClickNextButton()}
          />
        )}
      </div>
    </div>
  )
}

export default CustomScrollContent
