const STAGING_URL = 'https://neutrogena-labs.projectwebby.com/'
const STAGING_URL_API = 'https://neutrogena-admin.projectwebby.com'

const arrayYearForm = (fromYear) => {
  let years = []
  for (let i = fromYear; i >= fromYear - 99; i--) {
    years.push(i)
  }
  return years
}

export { STAGING_URL, STAGING_URL_API, arrayYearForm }
