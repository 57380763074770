/**
 * Jack Dao
 */
import React, { useState } from 'react'
import './TVDemo.scss'
import { Button, Popup } from '../../components'
import { SharePopup } from '../../containers'
import { STAGING_URL } from '../../Config'

import right_arrow from '../../images/right-arrow.svg'
import share_icon from '../../images/share_icon.png'

// import tv_demo_pierced_agar_demo from '../../images/TestingZone/tv_demo_pierced_agar_demo.png'
import pierced_agar_conventional from '../../images/TestingZone/pierced_agar_conventional_cleanser.png'
import pierced_agar_neutrogena_labs from '../../images/TestingZone/pierced_agar_neutrogena_lab.png'
import tv_demo_foam from '../../images/TestingZone/tv_demo_foam.png'
import tv_demo_ph from '../../images/TestingZone/tv_demo_ph.png'

const START = 'start'
const MAGNETIC_DEMO = 'magnetic-demo'
const PIERCED_AGAR_DEMO = 'pierced-agar-demo'
const FOAM_DEMO = 'foam-demo'
const PH_DEMO = 'ph-demo'
const SHARE = 'share'

// const STYLE_POPUP = [
//   {
//     padding: '2% 2% 4% 2%'
//   },
//   {
//     padding: '2% 2% 4% 2%'
//   },
//   {
//     padding: '2% 2% 4% 2%'
//   },
//   {
//     padding: '2% 2% 4% 2%'
//   },
//   {
//     padding: '2% 2% 4% 2%'
//   }
// ]

const TVDemo = ({
  t,
  show,
  onClose,
  onClick,
  showShareButton,
  onShareClick
}) => {
  const [currentPage, setCurrentPage] = useState(START)
  const [shareBackCode, setShareBackCode] = useState(START)
  function breakLine(text) {
    var br = React.createElement('br')
    var regex = /(<br\/>)/g
    return text.split(regex).map(function (line, index) {
      return line.match(regex) ? <br key={'key_' + index} /> : line
    })
  }

  const handleOnClick = (code) => {
    setCurrentPage(code)
  }

  const renderMagneticDemo = () => {
    return (
      <div className='tv-magnetic-demo'>
        <div className='container'>
          <div className='left-content'>
            <div className='header'>
              <div className='head-title'>{t('video_2')}</div>
              <div className='normal-text'>{t('video_2_content')}</div>
            </div>
          </div>
          <div className='right-content'>
            <iframe
              title='video'
              width='100%'
              height='100%'
              src='https://www.youtube.com/embed/H8xG-eDkqjA?autoplay=1'
              frameBorder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen='allowFullScreen'
              //   mozallowfullscreen='mozallowfullscreen'
              //   msallowfullscreen='msallowfullscreen'
              //   oallowfullscreen='oallowfullscreen'
            />
          </div>
        </div>
        <div className='btn-group'>
          <div
            className={`main-button button`}
            onClick={() => {
              // share()
              if (onClick) {
                // onClick('share')
              }
              setShareBackCode(currentPage)
              setCurrentPage(SHARE)
            }}
          >
            <div className='row'>
              <img className='img-icon' src={share_icon} alt='' />
              <div className='button-title text-center bold-text'>
                {t('share')}
              </div>
            </div>
            <img className='button-icon' src={right_arrow} alt='' />
          </div>
          <Button
            onClick={() => {
              if (onClick) {
                onClick('get_samples')
                setCurrentPage(START)
              }
            }}
            title={t('get_samples')}
          />
          <Button
            onClick={() => {
              setCurrentPage(START)
            }}
            title={t('back')}
          />
        </div>
      </div>
    )
  }

  const renderPiercedAgarDemo = () => {
    return (
      <div className='tv-pierced-agar-demo'>
        <div className='container'>
          <div className='left-content'>
            <div className='header'>
              <div className='head-title'>{t('video_1')}</div>
              <div className='normal-text'>
                {breakLine(t('video_1_content'))}
              </div>
            </div>
          </div>
          <div className='right-content'>
            {/* <img src={tv_demo_pierced_agar_demo} alt='' /> */}
            <div className='row'>
              <div className='col conventional_cleanser'>
                <img src={pierced_agar_conventional} alt='' />
                <div className='bold-text'>{t('conventional_cleanser')}</div>
                <div className='normal-text'>
                  {breakLine(t('conventional_cleanser_content'))}
                </div>
              </div>
              <div className='col neutrogena_labs_magnetic_gel'>
                <img src={pierced_agar_neutrogena_labs} alt='' />
                <div
                  className='bold-text'
                  dangerouslySetInnerHTML={{
                    __html: t('neutrogena_labs_magnetic_gel')
                  }}
                />
                <div className='normal-text'>
                  {breakLine(t('neutrogena_labs_magnetic_gel_content'))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='btn-group'>
          <div
            className={`main-button button`}
            onClick={() => {
              // share()
              // if (onClick) {
              //   onClick('share')
              //   setCurrentPage(START)
              // }
              setShareBackCode(currentPage)
              setCurrentPage(SHARE)
            }}
          >
            <div className='row'>
              <img className='img-icon' src={share_icon} alt='' />
              <div className='button-title text-center bold-text'>
                {t('share')}
              </div>
            </div>
            <img className='button-icon' src={right_arrow} alt='' />
          </div>
          <Button
            onClick={() => {
              if (onClick) {
                onClick('get_samples')
                setCurrentPage(START)
              }
            }}
            title={t('get_samples')}
          />
          <Button
            onClick={() => {
              setCurrentPage(START)
            }}
            title={t('back')}
          />
        </div>
      </div>
    )
  }

  const renderFoamDemo = () => {
    return (
      <div className='tv-foam-demo'>
        <div className='container'>
          <div className='left-content'>
            <div className='header'>
              <div className='head-title'>{t('the_foaming_demo')}</div>
              <div className='normal-text'>
                {breakLine(t('the_foaming_demo_content'))}
              </div>
            </div>
          </div>
          <div className='right-content'>
            <img src={tv_demo_foam} alt='' />
            <div className='row'>
              <div className='col conventional_cleanser'>
                <div className='bold-text'>
                  {t('conventional_gel_cleanser')}
                </div>
                <div className='normal-text'>
                  {breakLine(t('conventional_gel_cleanser_content'))}
                </div>
              </div>
              <div className='col neutrogena_labs_magnetic_gel'>
                <div
                  className='bold-text'
                  dangerouslySetInnerHTML={{
                    __html: t('neutrogena_labs_magnetic_gel')
                  }}
                />
                <div className='normal-text'>
                  {breakLine(t('neutrogena_demo_foam_content'))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='btn-group'>
          <div
            className={`main-button button`}
            onClick={() => {
              // share()
              // if (onClick) {
              //   onClick('share')
              //   setCurrentPage(START)
              // }
              setShareBackCode(currentPage)
              setCurrentPage(SHARE)
            }}
          >
            <div className='row'>
              <img className='img-icon' src={share_icon} alt='' />
              <div className='button-title text-center bold-text'>
                {t('share')}
              </div>
            </div>
            <img className='button-icon' src={right_arrow} alt='' />
          </div>
          <Button
            onClick={() => {
              if (onClick) {
                onClick('get_samples')
                setCurrentPage(START)
              }
            }}
            title={t('get_samples')}
          />
          <Button
            onClick={() => {
              setCurrentPage(START)
            }}
            title={t('back')}
          />
        </div>
      </div>
    )
  }

  const renderPhDemo = () => {
    return (
      <div className='tv-ph-demo'>
        <div className='container'>
          <div className='left-content'>
            <div className='header'>
              <div
                className='head-title'
                dangerouslySetInnerHTML={{
                  __html: t('the_ph_test')
                }}
              />
              <div className='normal-text'>
                {breakLine(t('ph_test_content'))}
              </div>
            </div>
          </div>
          <div className='right-content'>
            <img src={tv_demo_ph} alt='' />
            <div className='row'>
              <div className='col conventional_cleanser'>
                <div className='bold-text'>{t('conventional_cleanser')}</div>
                <div className='normal-text'>
                  {breakLine(t('conventional_cleanser_ph_demo'))}
                </div>
              </div>
              <div className='col neutrogena_labs_magnetic_gel'>
                <div
                  className='bold-text'
                  dangerouslySetInnerHTML={{
                    __html: t('neutrogena_labs_magnetic_gel')
                  }}
                />
                <div className='normal-text'>
                  {breakLine(t('neutrogena_ph_demo'))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='btn-group'>
          <div
            className={`main-button button`}
            onClick={() => {
              // share()
              // if (onClick) {
              //   onClick('share')
              //   setCurrentPage(START)
              // }
              setShareBackCode(currentPage)
              setCurrentPage(SHARE)
            }}
          >
            <div className='row'>
              <img className='img-icon' src={share_icon} alt='' />
              <div className='button-title text-center bold-text'>
                {t('share')}
              </div>
            </div>
            <img className='button-icon' src={right_arrow} alt='' />
          </div>
          <Button
            onClick={() => {
              if (onClick) {
                onClick('get_samples')
                setCurrentPage(START)
              }
            }}
            title={t('get_samples')}
          />
          <Button
            onClick={() => {
              setCurrentPage(START)
            }}
            title={t('back')}
          />
        </div>
      </div>
    )
  }

  if (currentPage === SHARE) {
    return (
      <SharePopup
        type={'share-the-experience'}
        shareUrl={STAGING_URL}
        show={currentPage === SHARE}
        onClose={() => {
          setCurrentPage(START)
          onClose()
        }}
        onBack={() => {
          setCurrentPage(shareBackCode)
        }}
        t={t}
        onClick={() => {}}
      />
    )
  }
  return (
    <Popup
      customClass={`tv-demo-popup ${currentPage}`}
      containerStyle={{ padding: '2% 2% 4% 2%' }}
      isOpen={show}
      onClose={() => {
        setCurrentPage(START)
        onClose()
      }}
      showShareButton={showShareButton}
      onShareClick={() => {
        setShareBackCode(currentPage)
        setCurrentPage(SHARE)
      }}
    >
      {currentPage === START && (
        <div className='tv-demo-start'>
          <div className='container'>
            <div className='header'>
              <div className='head-title'>
                {breakLine(t('backed_by_science'))}
              </div>
              <div
                className='bold-text'
                dangerouslySetInnerHTML={{
                  __html: t('tv_demo_start_header_des')
                }}
              />
            </div>
            <div className='btn-group'>
              <div className='row'>
                <Button
                  className={'button'}
                  title={t('video_2')}
                  onClick={() => {
                    // if (onClick) {
                    //   onClick('the_magnetic_cleanser_demo')
                    // }
                    handleOnClick(MAGNETIC_DEMO)
                  }}
                ></Button>
                <Button
                  className={'button ml50'}
                  title={t('video_1')}
                  onClick={() => {
                    // if (onClick) {
                    //   onClick('the_pierced_agar_demo')
                    // }
                    handleOnClick(PIERCED_AGAR_DEMO)
                  }}
                ></Button>
              </div>
              <div className='row'>
                <Button
                  className={'button'}
                  title={t('the_foaming_demo')}
                  onClick={() => {
                    // if (onClick) {
                    //   onClick('the_foaming_demo')
                    // }
                    handleOnClick(FOAM_DEMO)
                  }}
                ></Button>
                <Button
                  className={'button ml50'}
                  title={t('the_ph_demo')}
                  onClick={() => {
                    // if (onClick) {
                    //   onClick('the_ph_demo')
                    // }
                    handleOnClick(PH_DEMO)
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentPage === MAGNETIC_DEMO && renderMagneticDemo()}
      {currentPage === PIERCED_AGAR_DEMO && renderPiercedAgarDemo()}
      {currentPage === FOAM_DEMO && renderFoamDemo()}
      {currentPage === PH_DEMO && renderPhDemo()}
    </Popup>
  )
}

export default TVDemo
