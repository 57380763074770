import React, { useState, useEffect } from 'react'
import { Button, ImageBackground, Popup } from '../../components'
import './styles.scss'
import experiential_zone_bg from '../../images/experiential_zone/experiential_zone_bg.jpg'

import blue_magnet_on from '../../images/experiential_zone/blue_magnet_on.png'
import blue_icon_animate from '../../images/blue_icon_animate.gif'
import orange_icon_animate from '../../images/orange_icon_animate.gif'

import orange_magnet_on from '../../images/experiential_zone/orange_magnet_on.png'

import magnetic_logo from '../../images/magnetic_cleasing_logo.png'
import { useHistory, useParams } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const NONE = 0
const START_GAME = 1
const GAME_SITE = 2
const VIDEO_POP_UP = 3

function ExperientialZone({ t, location }) {
  const [skinType, setSkinType] = useState(
    location.state ? location.state.skinType : 'oily'
  )

  const [openPopup, setOpenPopup] = useState(NONE)
  const [showVideo, setShowVideo] = useState(false)
  const [showVideoAfterGame, setShowVideoAfterGame] = useState(false)
  const history = useHistory()

  return (
    <div className={`experiential-zone`}>
      <ImageBackground backgroundImage={experiential_zone_bg}>
        {skinType === 'oily' ? (
          <>
            <Popup
              isOpen={showVideo}
              containerStyle={{ padding: '4% 4% 4% 4%' }}
              onClose={() => setShowVideo(false)}
            >
              <iframe
                title='video'
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/AVBsv2IWxXc?autoplay=1'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen='allowFullScreen'
                //   mozallowfullscreen='mozallowfullscreen'
                //   msallowfullscreen='msallowfullscreen'
                //   oallowfullscreen='oallowfullscreen'
              />
            </Popup>

            <Popup
              isOpen={showVideoAfterGame}
              containerStyle={{ padding: '4% 4% 4% 4%' }}
              onClose={() => setShowVideoAfterGame(false)}
            >
              <iframe
                title='video'
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/j-wGz0l4qrc?autoplay=1'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen='allowFullScreen'
                //   mozallowfullscreen='mozallowfullscreen'
                //   msallowfullscreen='msallowfullscreen'
                //   oallowfullscreen='oallowfullscreen'
              />
            </Popup>

            <img
              onClick={() => setOpenPopup(START_GAME)}
              className='icon orange-magnet-on'
              src={orange_magnet_on}
              alt=''
            />
          </>
        ) : (
          <>
            <Popup
              isOpen={showVideo}
              containerStyle={{ padding: '4% 4% 4% 4%' }}
              onClose={() => setShowVideo(false)}
            >
              <iframe
                title='video'
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/AVBsv2IWxXc?autoplay=1'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen='allowFullScreen'
                //   mozallowfullscreen='mozallowfullscreen'
                //   msallowfullscreen='msallowfullscreen'
                //   oallowfullscreen='oallowfullscreen'
              />
            </Popup>

            <Popup
              isOpen={showVideoAfterGame}
              containerStyle={{ padding: '4% 4% 4% 4%' }}
              onClose={() => setShowVideoAfterGame(false)}
            >
              <iframe
                title='video'
                width='100%'
                height='100%'
                src='https://www.youtube.com/embed/j-wGz0l4qrc?autoplay=1'
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen='allowFullScreen'
                //   mozallowfullscreen='mozallowfullscreen'
                //   msallowfullscreen='msallowfullscreen'
                //   oallowfullscreen='oallowfullscreen'
              />
            </Popup>

            <img
              onClick={() => setOpenPopup(START_GAME)}
              className='icon blue-magnet-on'
              src={blue_magnet_on}
              alt=''
            />
          </>
        )}
        <div className='direction-group'>
          <div
            className='next-page'
            onClick={() => {
              history.push('/testing-zone', {
                skinType: skinType
              })
            }}
          />
          <div className='normal-text title'>{t('click_and_move')}</div>
          <div
            className='pre-page'
            onClick={() => {
              history.push('/entrance')
            }}
          />
        </div>
        <Popup
          isOpen={openPopup === START_GAME}
          onClose={() => setOpenPopup(NONE)}
        >
          <div className='game-introduction-popup-container'>
            <div className='game-introduction-popup-content'>
              <div className='left-part'>
                <img className='magnetic-logo' src={magnetic_logo} alt='' />
              </div>

              <div className='right-part'>
                <div className='game-introduction-text'>
                  {/* <div className='bold-text'>{t('game_intro')}</div>
                  <div
                    className='normal-text'
                    dangerouslySetInnerHTML={{
                      __html: t('game_description')
                    }}
                  ></div> */}
                  <div className='bold-text'>{t('game_prizes')}</div>
                  <div className='bottom-button-group'>
                    <Button
                      title={t('play')}
                      onClick={() => {
                        setOpenPopup(GAME_SITE)
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth'
                        })
                        document.body.style.overflow = 'hidden'
                      }}
                    ></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <Popup
          containerStyle={{
            padding: '0px',
            width: '100%',
            height: '100%',
            maxHeight: '100vh',
            backgroundColor: 'black'
          }}
          rootStyle={{
            display: 'block'
          }}
          isOpen={openPopup === GAME_SITE}
          onClose={() => {
            document.body.style.overflow = 'auto'
            setOpenPopup(NONE)
            setShowVideoAfterGame(true)
          }}
        >
          <iframe
            title='game'
            width='100%'
            height='100%'
            src='../Game/index.html'
            allowFullScreen='allowFullScreen'
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          />
        </Popup>
      </ImageBackground>
    </div>
  )
}

export default withTranslation()(ExperientialZone)
