import React, { useState, useEffect } from 'react'
import { Popup } from '../components'
import { withTranslation, language } from 'react-i18next'
import useWindowSize from '../hooks/useWindowSize'
import useBeforeUnload from '../hooks/useBeforeUnload'
import { LeavingSoSoon } from '../containers'

import './ZoomAnimator.scss'
function ZoomAnimator(props) {
	const [animationClass, setAnimationClass] = useState('')
	const [showPopup, setShowPopup] = useState(false)
	const [leavingPopup, setLeavingPopup] = useState(false)
	const routes = [
		'/',
		'/entrance',
		'/experiential-zone',
		'/testing-zone',
		'/sales-zone'
	]
	const size = useWindowSize()
	useEffect(() => {
		const curIndex = routes.indexOf(props.curLocation)
		const prevIndex = routes.indexOf(props.prevLocation)
		let animationClass = ''
		let timeOut = 0
		if (curIndex > prevIndex) {
			animationClass = 'zoom-in-animation'
			timeOut = 1000
		} else {
			animationClass = 'fade-out-animation'
			timeOut = 750
		}
		if (props.prevChild && props.prevLocation !== props.curLocation) {
			setAnimationClass(animationClass)
		}
		setTimeout(() => {
			props.animationCallback()
			setAnimationClass('')
		}, timeOut)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.prevChild, props.prevLocation])

	useEffect(() => {
		if (size.width < 450 && size.width <= size.height) {
			setShowPopup(true)
		} else {
			setShowPopup(false)
		}
	}, [size])

	useBeforeUnload((event) => {
		setLeavingPopup(true)
		return event.preventDefault()
	})

	const renderPopup = () => (
		<Popup
			containerStyle={{
				width: '100%',
				height: '100%'
			}}
			rootStyle={{ alignItems: 'flex-start' }}
			isOpen={showPopup}
			onClose={() => setShowPopup(false)}>
			<div className='tilt-phone-popup'>
				<div className='tilt-phone-text'>
					Please tilt your phone to landscape view for a better experience.
				</div>
			</div>
		</Popup>
	)
	return (
		<div className={`zoom-container ${animationClass}`}>
			{props.prevChild || props.curChild}
			{renderPopup()}
			<LeavingSoSoon
				t={props.t}
				show={leavingPopup}
				onClose={() => {
					setLeavingPopup(false)
				}}
				onClick={() => {}}
			/>
		</div>
	)
}

export default withTranslation()(ZoomAnimator)
