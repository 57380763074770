import React from 'react'
import './App.scss'
import './fonts/fonts.scss'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Home, Entrance, TestingZone, SalesZone } from './pages'
import { ExperientialZone } from './containers'
import DefaultLayout from './layout/DefaultLayout'
import AnimatorLayout from './layout/AnimatorLayout'

const animateSwitch = (CustomSwitch, AnimatorComponent) => ({ children }) => (
  <Route
    render={({ location }) => (
      <AnimatorComponent uniqKey={location.pathname}>
        <CustomSwitch location={location}>{children}</CustomSwitch>
      </AnimatorComponent>
    )}
  />
)

const AnimateSwitch = animateSwitch(Switch, AnimatorLayout)

function App() {
  return (
    <>
      <Router>
        <AnimateSwitch>
          <DefaultLayout exact path='/' component={Home}></DefaultLayout>
          <DefaultLayout
            exact
            path='/sales-zone'
            component={SalesZone}
          ></DefaultLayout>
          <DefaultLayout
            exact
            path='/testing-zone'
            component={TestingZone}
          ></DefaultLayout>
          <DefaultLayout
            exact
            path='/experiential-zone'
            component={ExperientialZone}
          ></DefaultLayout>
          <DefaultLayout
            exact
            path='/entrance'
            component={Entrance}
          ></DefaultLayout>
          <DefaultLayout exact path='/:lang' component={Home}></DefaultLayout>
          <DefaultLayout
            exact
            path='/:lang/sales-zone'
            component={SalesZone}
          ></DefaultLayout>
          <DefaultLayout
            exact
            path='/:lang/testing-zone'
            component={TestingZone}
          ></DefaultLayout>
          <DefaultLayout
            exact
            path='/:lang/entrance'
            component={Entrance}
          ></DefaultLayout>
        </AnimateSwitch>
      </Router>
    </>
  )
}

export default App
