import React, { useState } from 'react'
import { Button, ImageBackground, Popup } from '../../components'
import entrance_bg from '../../images/entrance_bg.jpg'
import './styles.scss'
import { withTranslation } from 'react-i18next'
import { SharePopup } from '../../containers'
import { useHistory } from 'react-router-dom'
import { STAGING_URL } from '../../Config'

const NONE = 0
const DONT_KNOW_POPUP = 1
const DRY_POPUP = 2
const OILY_POPUP = 3
const SHARE_DRY_POPUP = 4
const SHARE_OILY_POPUP = 5

function Entrance({ t }) {
  // const [openDontKnowPopup, setOpenDontKnowPopup] = useState(false)
  const [openPopupType, setOpenPopupType] = useState(0)
  const [answers, setAnswers] = useState({})
  const [showExperientialZone, setShowExperientialZone] = useState(false)
  const [entranceClass, setEntranceClass] = useState('')
  const [skinType, setSkinType] = useState('')
  const history = useHistory()
  const onClickNextButton = () => {
    if (answers[1] === 'yes') {
      setOpenPopupType(DRY_POPUP)
    } else {
      setOpenPopupType(OILY_POPUP)
    }
    setAnswers({})
  }

  const onClickBackToLab = () => {
    setOpenPopupType(NONE)
  }

  const onClickShareButton = () => {
    if (openPopupType === OILY_POPUP) {
      setOpenPopupType(SHARE_OILY_POPUP)
    }
    if (openPopupType === DRY_POPUP) {
      setOpenPopupType(SHARE_DRY_POPUP)
    }
  }

  const getAnswer = (question, answer) => {
    let obj = {}
    obj[question] = answer
    setAnswers((prev) => ({
      ...prev,
      ...obj
    }))
  }

  const QUESTION = [
    {
      id: 1,
      question: t('find_out_skin_type_question_1')
    },
    {
      id: 2,
      question: t('find_out_skin_type_question_2')
    },
    {
      id: 3,
      question: t('find_out_skin_type_question_3')
    }
  ]

  const renderDontKnowPopup = () => (
    <Popup
      isOpen={openPopupType === DONT_KNOW_POPUP}
      onClose={() => setOpenPopupType(NONE)}
    >
      <div className='dont-know-question-group'>
        <div className='title-group'>
          <div className='head-title'>{t('dont_know_your_skin_type')}</div>
          <div className='bold-text' style={{ marginTop: '2%' }}>
            {t('find_out_skin_type')}
          </div>
        </div>

        <div className='question-group'>
          {QUESTION.map((item, index) => (
            <div key={item.id} className='question-answer-line'>
              <div className='normal-text question'>{item.question}</div>
              <div className='answer'>
                <Button
                  onClick={() => getAnswer(item.id, 'yes')}
                  className={'answer-button'}
                  showRightIcon={false}
                  isActive={answers[item.id] === 'yes'}
                  title={t('yes')}
                ></Button>
                <Button
                  onClick={() => getAnswer(item.id, 'no')}
                  className={'answer-button'}
                  showRightIcon={false}
                  isActive={answers[item.id] === 'no'}
                  title={t('no')}
                ></Button>
              </div>
            </div>
          ))}
        </div>
        <div className='bottom-button-group'>
          <Button onClick={onClickNextButton} title={t('next')}></Button>
        </div>
      </div>
    </Popup>
  )

  const renderDryPopup = () => (
    <Popup
      showCloseButton={false}
      isOpen={openPopupType === DRY_POPUP}
      onClose={() => setOpenPopupType(NONE)}
    >
      <div className='dry-popup-container'>
        <div className='dry-popup-content'>
          <div className='bold-text'>{t('your_skin_type_is')}</div>

          <div className='skin-type-text'>
            <div className='head-title'>{t('dry')}</div>
            <div className='bold-text'>&nbsp;{t('and_reactive')}</div>
          </div>

          <div
            dangerouslySetInnerHTML={{ __html: t('dry_skin_description') }}
            className='normal-text'
          ></div>
        </div>

        <div className='bottom-button-group'>
          <Button onClick={onClickShareButton} title={t('share')}></Button>
          <Button onClick={onClickBackToLab} title={t('back_to_lab')}></Button>
        </div>
      </div>
    </Popup>
  )

  const renderOilyPopup = () => (
    <Popup
      showCloseButton={false}
      isOpen={openPopupType === OILY_POPUP}
      onClose={() => setOpenPopupType(NONE)}
    >
      <div className='oily-popup-container'>
        <div className='oily-popup-content'>
          <div className='bold-text'>{t('your_skin_type_is')}</div>
          <div className='skin-type-text'>
            <div className='head-title'>{t('oily')}</div>
            <div className='bold-text'>&nbsp;{t('and_reactive')}</div>
          </div>

          <div
            dangerouslySetInnerHTML={{ __html: t('oily_skin_description') }}
            className='normal-text'
          ></div>
        </div>
        <div className='bottom-button-group'>
          <Button onClick={onClickShareButton} title={t('share')}></Button>
          <Button onClick={onClickBackToLab} title={t('back_to_lab')}></Button>
        </div>
      </div>
    </Popup>
  )

  const onClickArrow = (skinType) => {
    setOpenPopupType(NONE)
    setSkinType(skinType)
    history.push('/experiential-zone', {
      skinType: skinType
    })
  }

  // const renderExperientialZone = () => {
  //   return (
  //     <ExperientialZone
  //       show={showExperientialZone}
  //       t={t}
  //       skinType={skinType}
  //     ></ExperientialZone>
  //   )
  // }

  const renderEntrance = () => (
    <div
      className={`entrance ${entranceClass} ${
        showExperientialZone ? 'hidden' : ''
      }`}
    >
      <ImageBackground backgroundImage={entrance_bg}>
        <div className='entrance-container'>
          <div className='entrance-content'>
            <div
              dangerouslySetInnerHTML={{ __html: t('select_your_skin_type') }}
              className='introduction-text bold-text'
            ></div>
            <div className='skin-group'>
              <div className='left-part'>
                <div className='head-title'> {t('oily')}</div>
                <div className='bold-text'>{t('and_reactive_skin')}</div>
              </div>
              <div className='right-part'>
                <div className='head-title'>{t('dry')}</div>
                <div className='bold-text'>{t('and_reactive_skin')}</div>
              </div>
            </div>
            <div className='dont-know-button-wrapper'>
              {/* <Button
                onClick={() => setOpenPopupType(DONT_KNOW_POPUP)}
                title={t('dont_know')}
              ></Button> */}
            </div>
          </div>
          <div className='arrow-direction direction-group'>
            <div onClick={() => onClickArrow('oily')} className='arrow-mask'>
              <div className='next-page' />
            </div>
            <div onClick={() => onClickArrow('dry')} className='arrow-mask'>
              <div className='next-page' />
            </div>
          </div>
        </div>
        {renderDontKnowPopup()}
        {renderDryPopup()}
        {renderOilyPopup()}
        <SharePopup
          t={t}
          type='oily'
          shareUrl={STAGING_URL}
          show={openPopupType === SHARE_OILY_POPUP}
          onClose={() => setOpenPopupType(NONE)}
          onClick={() => {}}
        />
        <SharePopup
          t={t}
          type='dry'
          shareUrl={STAGING_URL}
          show={openPopupType === SHARE_DRY_POPUP}
          onClose={() => setOpenPopupType(NONE)}
          onClick={() => {}}
        />
      </ImageBackground>
    </div>
  )

  return (
    <div
      className='entrance-page'
      style={
        entranceClass === 'zoom-in-animation' && !showExperientialZone
          ? { overflow: 'hidden' }
          : {}
      }
    >
      {renderEntrance()}
      {/* {renderExperientialZone()} */}
    </div>
  )
}

export default withTranslation()(Entrance)
