import React from 'react'
import close_button from '../../images/close_buton.png'
import './styles.scss'
import arrow_share from '../../images/arrow_share.png'

function Popup({
  children,
  customClass,
  isOpen,
  onClose,
  onShareClick,
  containerStyle,
  rootStyle,
  contentStyle,
  closeButtonStyle,
  showCloseButton = true,
  showShareButton = false
}) {
  if (!isOpen) return null
  return (
    <div className={`popup ${customClass}`} style={rootStyle}>
      <div className='popup-container' style={containerStyle}>
        {showCloseButton && (
          <div
            onClick={onClose}
            className='popup-close-button'
            style={closeButtonStyle}
          >
            <img src={close_button} alt='' />
          </div>
        )}
        {showShareButton && (
          <div className='popup-share-button' onClick={onShareClick}>
            <img src={arrow_share} alt='' />
          </div>
        )}
        <div className='popup-content' style={contentStyle}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Popup
