import React, { useState } from 'react'
import { Button, Popup, ImageBackground } from '../../components'
import { useHistory } from 'react-router-dom'

import bg from '../../images/bg.jpg'
import magnetic_logo from '../../images/magnetic_cleasing_logo.png'
import './styles.scss'

function Home({ t }) {
  const [startPage, setStartPage] = useState(1)
  const [showVideo, setShowVideo] = useState(true)
  const history = useHistory()

  const onClickButton = () => {
    if (startPage === 1) {
      setStartPage(2)
    } else if (startPage === 2) {
      history.push('/entrance')
    }
  }

  return (
    <div className='home'>
      <ImageBackground backgroundImage={bg}>
        <div className='center-content'>
          <Popup
            isOpen={showVideo}
            containerStyle={{ padding: '4% 4% 4% 4%' }}
            onClose={() => setShowVideo(false)}
          >
            <iframe
              title='video'
              width='100%'
              height='100%'
              src='https://www.youtube.com/embed/AVBsv2IWxXc?autoplay=1'
              frameborder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen='allowfullscreen'
              //   mozallowfullscreen='mozallowfullscreen'
              //   msallowfullscreen='msallowfullscreen'
              //   oallowfullscreen='oallowfullscreen'
            />
          </Popup>
          {startPage === 1 ? (
            <>
              <div className='head-title'>{t('hello')}</div>
              <div className='bold-text'>{t('welcome_to_neutrogena_labs')}</div>
              <div className='normal-text'>{t('explore_the_lab')}</div>
            </>
          ) : (
            <img className='magnetic-logo' src={magnetic_logo} alt='' />
          )}
          <Button
            onClick={onClickButton}
            className='absolute-bottom-btn'
            title={startPage === 1 ? t('next') : t('enter_lab')}
          ></Button>
        </div>
      </ImageBackground>
    </div>
  )
}

export default Home
