/**
 * Jack Dao
 */
import React from 'react'
import './styles.scss'
import { Button, Popup } from '../../components'
import copy from 'copy-to-clipboard'
import {
  FacebookShareButton,
  LineShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share'

import hello_sticker_orange_border from '../../images/share/hello_sticker_orange_border.png'
import hello_sticker_blue_border from '../../images/share/hello_sticker_blue_border.png'

import product_benefit_1 from '../../images/TestingZone/product_benefit_1.png'
import product_benefit_2 from '../../images/TestingZone/product_benefit_2.png'
import product_benefit_3 from '../../images/TestingZone/product_benefit_3.png'
import product_benefit_4 from '../../images/TestingZone/product_benefit_4.png'

import share_experience_img from '../../images/share_experience_img.jpg'
import share_seeing_is_beliving from '../../images/share_seeing_is_beliving.jpg'
import share_game from '../../images/share_game.jpg'
import share_all_good_thing from '../../images/share_all_good_thing.jpg'

import facebook_icon from '../../images/share/facebook_white_icon.png'
import line_icon from '../../images/share/line_white_icon.png'
import telegram_icon from '../../images/share/telegram_white_icon.png'
import twitter_icon from '../../images/share/twitter_white_icon.png'
import whatsapp_icon from '../../images/share/whatsapp_white_icon.png'

const SharePopup = ({
  t,
  show,
  type,
  onClose,
  onBack = null,
  hiddenBackButton,
  shareUrl,
  onClick
}) => {
  const renderShareButton = ({ code, icon, title, className = 'mr11' }) => {
    if (code === 'facebook') {
      return (
        <FacebookShareButton key={code} url={shareUrl}>
          <div className={`main-button ${className ? className : ''}`}>
            {icon && <img className='button-icon' src={icon} alt='' />}
            <div className='button-title'>{title}</div>
          </div>
        </FacebookShareButton>
      )
    }
    if (code === 'line') {
      return (
        <LineShareButton key={code} url={shareUrl}>
          <div className={`main-button ${className ? className : ''}`}>
            {icon && <img className='button-icon' src={icon} alt='' />}
            <div className='button-title'>{title}</div>
          </div>
        </LineShareButton>
      )
    }
    if (code === 'whatsapp') {
      return (
        <WhatsappShareButton key={code} url={shareUrl}>
          <div className={`main-button ${className ? className : ''}`}>
            {icon && <img className='button-icon' src={icon} alt='' />}
            <div className='button-title'>{title}</div>
          </div>
        </WhatsappShareButton>
      )
    }
    if (code === 'telegram') {
      return (
        <TelegramShareButton key={code} url={shareUrl}>
          <div className={`main-button ${className ? className : ''}`}>
            {icon && <img className='button-icon' src={icon} alt='' />}
            <div className='button-title'>{title}</div>
          </div>
        </TelegramShareButton>
      )
    }
    if (code === 'twitter') {
      return (
        <TwitterShareButton key={code} url={shareUrl}>
          <div className={`main-button ${className ? className : ''}`}>
            {icon && <img className='button-icon' src={icon} alt='' />}
            <div className='button-title'>{title}</div>
          </div>
        </TwitterShareButton>
      )
    }
    if (code === 'back' && hiddenBackButton) {
      return null
    }
    return (
      <div
        key={code}
        className={`main-button ${className ? className : ''}`}
        onClick={() => {
          if (code === 'back') {
            if (onBack) {
              onBack()
            }
          } else {
            copy(shareUrl)
            alert('Copied to clipboard!')
          }
        }}
      >
        {icon && <img className='button-icon' src={icon} alt='' />}
        <div className='button-title'>{title}</div>
      </div>
    )
  }

  const renderSkinType = (type) => {
    let imgSticker =
      type === 'oily' ? hello_sticker_orange_border : hello_sticker_blue_border
    let title = t('oily')
    if (type === 'oily') {
      title = t('dry')
    }
    return (
      <div className='share-oily-skin'>
        <div className='head-title'>{t('share_your_skin_type')}</div>
        <div className={`group-logo ${type === 'oily' ? 'orange' : 'blue'}`}>
          <img className='sticker-icon' src={imgSticker} alt='' />
          <div className='row'>
            <div className='bold-text'>{t('i_have')}</div>
            <div className='head-title margin-text'>{title}</div>
            <div className='bold-text'>{t('and_reactive_skin')}</div>
          </div>
        </div>
        <div
          className='description'
          dangerouslySetInnerHTML={{ __html: t('share_your_skin_type_des') }}
        />
      </div>
    )
  }

  const renderProductBenefit = () => {
    return (
      <div className='share-product-benefit'>
        <div className='head-title'>{t('be_the_friend_with_benefit')}</div>
        <div className='group-logo '>
          <div className='row mt16'>
            <img className='sticker-icon mr5' src={product_benefit_1} alt='' />
            <img className='sticker-icon' src={product_benefit_2} alt='' />
          </div>
          <div className='row mt5'>
            <img className='sticker-icon mr5' src={product_benefit_4} alt='' />
            <img className='sticker-icon' src={product_benefit_3} alt='' />
          </div>
        </div>

        <div
          className='description'
          dangerouslySetInnerHTML={{
            __html: t('be_the_friend_with_benefit_des')
          }}
        />
      </div>
    )
  }

  const renderShareTheExperience = () => {
    return (
      <div className='share-the-experience'>
        <div className='head-title'>{t('share_the_magnetic_experience')}</div>
        <div className='group-logo'>
          <img className='img-content' src={share_experience_img} alt='' />
        </div>
        <div
          className='description'
          dangerouslySetInnerHTML={{
            __html: t('share_the_magnetic_experience_des')
          }}
        />
      </div>
    )
  }

  const renderSeeingIsBeliving = () => {
    return (
      <div className='share-seeing-is-beliving'>
        <div className='head-title'>{t('seeing_is_beliving')}</div>
        <div className='group-logo'>
          <img className='img-content' src={share_seeing_is_beliving} alt='' />
        </div>
        <div
          className='description'
          dangerouslySetInnerHTML={{
            __html: t('seeing_is_beliving_des')
          }}
        />
      </div>
    )
  }

  const renderShareGame = () => {
    return (
      <div className='share-game'>
        <div className='head-title'>{t('share_game_title')}</div>
        <div className='group-logo'>
          <img className='img-content' src={share_game} alt='' />
        </div>
        <div
          className='description'
          dangerouslySetInnerHTML={{
            __html: t('share_game_title_des')
          }}
        />
      </div>
    )
  }

  const renderShareAllGoodThing = () => {
    return (
      <div className='share-all-good-thing'>
        <div className='head-title'>{t('share_all_good_thing_title')}</div>
        <div className='group-logo'>
          <img className='img-content' src={share_all_good_thing} alt='' />
        </div>
        <div
          className='description'
          dangerouslySetInnerHTML={{
            __html: t('share_all_good_thing_des')
          }}
        />
      </div>
    )
  }

  return (
    <Popup isOpen={show} onClose={onClose}>
      <div className='share-container'>
        {type === 'oily' && renderSkinType(type)}
        {type === 'dry' && renderSkinType(type)}
        {type === 'product-benefit' && renderProductBenefit()}
        {type === 'share-the-experience' && renderShareTheExperience()}
        {type === 'share-seeing-is-beliving' && renderSeeingIsBeliving()}
        {type === 'share-game' && renderShareGame()}
        {type === 'share-all-good-thing' && renderShareAllGoodThing()}
        <div className='row'>
          {[
            {
              icon: facebook_icon,
              title: t('facebook'),
              code: 'facebook'
            },
            {
              icon: whatsapp_icon,
              title: t('whatsapp'),
              code: 'whatsapp'
            },
            {
              icon: line_icon,
              title: t('line'),
              code: 'line'
            },
            {
              icon: telegram_icon,
              title: t('telegram'),
              code: 'telegram'
            },
            {
              icon: twitter_icon,
              title: t('twitter'),
              code: 'twitter'
            },
            {
              title: t('copy_link'),
              // className: null,
              code: 'copy_link'
            },
            {
              title: t('back'),
              className: null,
              code: 'back'
            }
          ].map((item) => renderShareButton(item))}
        </div>
      </div>
    </Popup>
  )
}

export default SharePopup
