/**
 * Jack Dao
 */
import React, { useState } from 'react'
import './styles.scss'
import { useHistory } from 'react-router-dom'
import { STAGING_URL } from '../../Config'

import { GetSamples, SharePopup } from '../../containers'
import {
  Button,
  ImageBackground,
  Popup,
  CustomScrollContent
} from '../../components'
import { withTranslation } from 'react-i18next'

import LandingPopup from './LandingPopup'
import ProductBenefit from './ProductBenefit'
import TVDemo from './TVDemo'
import PowerOfMagnetic from './PowerOfMagnetic'

import blue_magnet_on from '../../images/TestingZone/blue_magnet_on.png'
import orange_magnet_on from '../../images/TestingZone/orange_magnet_on.png'
import blue_magnet_off from '../../images/TestingZone/blue_magnet_off.png'
import orange_magnet_off from '../../images/TestingZone/orange_magnet_off.png'
import bg from '../../images/bg_testing_zone.jpg'

import wall_start_of_neutrogena_labs from '../../images/TestingZone/wall_start_of_neutrogena_labs.png'

import neutrogena_logo from '../../images/neutrogena_logo_white.png'
import understanding_magnetic_cleansing from '../../images/understanding_magnetic_cleansing.png'
import double_sticker from '../../images/double_sticker.png'

import right_arrow from '../../images/right-arrow.svg'
import share_icon from '../../images/share_icon.png'
// import product_benefit_1 from '../../images/TestingZone/product_benefit_1.png'
// import product_benefit_2 from '../../images/TestingZone/product_benefit_2.png'
// import product_benefit_3 from '../../images/TestingZone/product_benefit_3.png'
// import product_benefit_4 from '../../images/TestingZone/product_benefit_4.png'
// import product_benefit_5 from '../../images/TestingZone/product_benefit_5.png'

const NONE = 0
const PRODUCT_BENEFIT = 1
const BRAND_AND_PRODUCT = 2
const VIDEO_CHANNEL = 3
const NEUTROGENA_LABS = 4
const UNDERSTANDING_MAGNETIC = 5
const LANDING_POP_UP = 6
const GET_SAMPLES = 7
const SHARE_PRODUCT_BENEFIT = 8
const SHARE_SEEING_AND_BELIVING = 9
const SHARE_MAGNETIC_EXPERIENCE = 10
const TV_DEMO_START = 11
const POWER_OF_MAGNETIC = 12

const TestingZone = ({ t, location }) => {
  const [openPopup, setOpenPopup] = useState(NONE)
  const [shareBackCode, setShareBackCode] = useState(NONE)
  const [skinType, setSkinType] = useState(
    location.state ? location.state.skinType : 'oily'
  )
  const history = useHistory()

  const backToLAB = () => {
    setOpenPopup(NONE)
  }
  const getSamples = () => {
    setOpenPopup(GET_SAMPLES)
  }
  const share = () => {
    setShareBackCode(openPopup)
    setOpenPopup(SHARE_SEEING_AND_BELIVING)
  }

  function breakLine(text) {
    var br = React.createElement('br')
    var regex = /(<br\/>)/g
    return text.split(regex).map(function (line, index) {
      return line.match(regex) ? <br key={'key_' + index} /> : line
    })
  }

  const renderBrandAndProduct = () => {
    return (
      <Popup
        customClass={`wall-popup`}
        showCloseButton={true}
        isOpen={[NEUTROGENA_LABS, UNDERSTANDING_MAGNETIC].includes(openPopup)}
        onClose={() => setOpenPopup(NONE)}
        showShareButton={true}
        onShareClick={() => {
          setShareBackCode(openPopup)
          setOpenPopup(SHARE_MAGNETIC_EXPERIENCE)
        }}
      >
        {[NEUTROGENA_LABS, UNDERSTANDING_MAGNETIC].includes(openPopup) && (
          <CustomScrollContent
            defaultIndex={openPopup === NEUTROGENA_LABS ? 0 : 1}
            hiddenPreviewButton={true}
            className='wall-content'
            renderContent={(code) => {
              if (code === NEUTROGENA_LABS) {
                return (
                  <div className='start-of-neutrogena-labs'>
                    <div className='container'>
                      <div className='left-content'>
                        <div className='header'>
                          <div className='head-title'>
                            {t('magnetic_cleasing')}
                          </div>
                          <div
                            className='bold-text for-irritation'
                            dangerouslySetInnerHTML={{
                              __html: t('for_0_irritation_concern')
                            }}
                          />
                          <div className='normal-text des1'>
                            {t('wall_start_of_neutro_des1')}
                          </div>
                          <div className='bold-text des2'>
                            {breakLine(t('wall_start_of_neutro_des2'))}
                          </div>
                          <div
                            className='normal-text des3'
                            dangerouslySetInnerHTML={{
                              __html: t('wall_start_of_neutro_des3')
                            }}
                          />
                        </div>
                      </div>
                      <div className='right-content'>
                        <img src={wall_start_of_neutrogena_labs} alt='' />
                      </div>
                    </div>
                    <div className='btn-group'>
                      <Button
                        onClick={() => {
                          setOpenPopup(LANDING_POP_UP)
                        }}
                        title={t('back')}
                      />
                    </div>
                  </div>
                )
              }
              if (code === UNDERSTANDING_MAGNETIC) {
                return (
                  <div className='understanding_reactive_skin'>
                    <div className='container'>
                      <div className='left-content'>
                        <div className='header'>
                          <div className='bold-text'>
                            {breakLine(t('do_you_have'))}
                          </div>
                          <div className='head-title'>
                            {t('reactive_skin_question')}
                          </div>
                          <div
                            className='normal-text des1'
                            dangerouslySetInnerHTML={{
                              __html: t('wall_understanding_reactive_skin_des1')
                            }}
                          />
                          <div
                            className='normal-text des2'
                            dangerouslySetInnerHTML={{
                              __html: t('wall_understanding_reactive_skin_des2')
                            }}
                          />
                          <div
                            className='normal-text des3'
                            dangerouslySetInnerHTML={{
                              __html: t('wall_understanding_reactive_skin_des3')
                            }}
                          />
                        </div>
                      </div>
                      <div className='right-content'>
                        <div className='video-content'>
                          <iframe
                            title='video'
                            width='100%'
                            height='100%'
                            src='https://www.youtube.com/embed/hUBjQ8iUuEU?autoplay=1'
                            frameBorder='0'
                            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen='allowFullScreen'
                            //   mozallowfullscreen='mozallowfullscreen'
                            //   msallowfullscreen='msallowfullscreen'
                            //   oallowfullscreen='oallowfullscreen'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='btn-group'>
                      <Button
                        onClick={() => {
                          setOpenPopup(LANDING_POP_UP)
                        }}
                        title={t('back')}
                      />
                    </div>
                  </div>
                )
              }
              return null
            }}
            data={[NEUTROGENA_LABS, UNDERSTANDING_MAGNETIC]}
            onSlideIndexChange={(index) => {}}
          />
        )}
      </Popup>
    )
  }

  const renderVideoChannel = () => {
    const VIDEO_CHANNELS = [
      {
        section_title: t('promotional_video'),
        videos: [
          {
            title: t('promotional_video_1'),
            description: '',
            url: ''
          },
          {
            title: t('promotional_video_2'),
            description: '',
            url: ''
          }
        ]
      },
      {
        section_title: t('in_the_neutrogena_lab'),
        videos: [
          {
            title: t('video_1'),
            description: t('video_1_content'),
            url: ''
          },
          {
            title: t('video_2'),
            description: t('video_2_content'),
            url: ''
          },
          {
            title: t('video_3'),
            description: t('video_3_content'),
            url: ''
          },
          {
            title: t('video_4'),
            description: t('video_4_content'),
            url: ''
          },
          {
            title: t('video_5'),
            description: t('video_5_content'),
            url: ''
          }
        ]
      },
      {
        section_title: t('influencers_and_their_magnetic_reviews'),
        videos: [
          {
            title: t('review_1'),
            description: '',
            url: ''
          },
          {
            title: t('review_2'),
            description: '',
            url: ''
          }
        ]
      }
    ]

    return (
      <Popup
        isOpen={openPopup === VIDEO_CHANNEL}
        containerStyle={{ padding: '4% 1% 3% 4%' }}
        onClose={() => setOpenPopup(NONE)}
        showShareButton={true}
        onShareClick={() => {
          setOpenPopup(SHARE_SEEING_AND_BELIVING)
        }}
      >
        <div className='video-channel-container'>
          {VIDEO_CHANNELS.map((video_channel, index) => (
            <div key={index} className='video-channel'>
              <div className='bold-text section-title'>
                {video_channel.section_title}
              </div>
              <div className='video-group'>
                {video_channel.videos.map((video, idx) => (
                  <div key={idx} className='video-item'>
                    <div className='video'>
                      <iframe
                        width='100%'
                        height='100%'
                        src='https://www.youtube.com/embed/9xwazD5SyVg'
                        frameborder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen='allowfullscreen'
                      ></iframe>
                    </div>
                    <div className='bold-text'>{video.title}</div>
                    <div className='normal-text'>{video.description}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className='bottom-button-group'>
            <div
              className={`main-button button`}
              onClick={() => {
                share()
              }}
            >
              <div className='row'>
                <img className='img-icon' src={share_icon} alt='' />
                <div className='button-title text-center bold-text'>
                  {t('share')}
                </div>
              </div>
              <img className='button-icon' src={right_arrow} alt='' />
            </div>
            <Button onClick={() => getSamples()} title={t('get_samples')} />
            <Button onClick={() => backToLAB()} title={t('back_to_lab')} />
          </div>
        </div>
      </Popup>
    )
  }

  return (
    <div className='testing-zone'>
      <ImageBackground backgroundImage={bg}>
        <img
          onClick={() => setOpenPopup(TV_DEMO_START)}
          className='icon blue-magnet-on'
          src={blue_magnet_on}
          alt=''
        />
        <img
          // onClick={() => setOpenPopup(VIDEO_CHANNEL)}
          className='icon blue-magnet-off'
          src={blue_magnet_off}
          alt=''
        />
        <img
          onClick={() => setOpenPopup(LANDING_POP_UP)}
          className='icon orange-magnet-on'
          src={orange_magnet_on}
          alt=''
        />
        <img
          // onClick={() => setOpenPopup(LANDING_POP_UP)}
          className='icon orange-magnet-off'
          src={orange_magnet_off}
          alt=''
        />

        <div className='direction-group'>
          <div
            className='next-page'
            onClick={() => {
              history.push('/sales-zone')
            }}
          />
          <div className='normal-text title'>{t('click_and_move')}</div>
          <div
            className='pre-page'
            onClick={() => {
              history.push('/experiential-zone', {
                skinType: skinType
              })
            }}
          />
        </div>

        {renderBrandAndProduct()}
        {renderVideoChannel()}
        <LandingPopup
          t={t}
          show={openPopup === LANDING_POP_UP}
          onClose={() => {
            setOpenPopup(NONE)
          }}
          onClick={(code) => {
            if (code === 'magnetic_cleansing') {
              setOpenPopup(PRODUCT_BENEFIT)
            }
            if (code === 'understanding_reactive_skin') {
              setOpenPopup(UNDERSTANDING_MAGNETIC)
            }
            if (code === 'the_start_of_neutrogena') {
              setOpenPopup(NEUTROGENA_LABS)
            }
            if (code === 'power_of_magnetic_cleansing') {
              setOpenPopup(POWER_OF_MAGNETIC)
            }
          }}
          showShareButton={true}
          onShareClick={() => {
            setShareBackCode(openPopup)
            setOpenPopup(SHARE_MAGNETIC_EXPERIENCE)
          }}
        />
        <ProductBenefit
          t={t}
          show={openPopup === PRODUCT_BENEFIT}
          onClose={() => {
            setOpenPopup(NONE)
          }}
          onClick={(code) => {
            if (code === 'watch_video') {
              setOpenPopup(VIDEO_CHANNEL)
            }
            if (code === 'share') {
              setShareBackCode(PRODUCT_BENEFIT)
              setOpenPopup(SHARE_PRODUCT_BENEFIT)
            }
            if (code === 'get_samples') {
              setOpenPopup(GET_SAMPLES)
            }
            if (code === 'back_to_lab') {
              backToLAB()
            }
            if (code === 'back') {
              setOpenPopup(LANDING_POP_UP)
            }
          }}
          showShareButton={true}
          onShareClick={() => {
            setShareBackCode(PRODUCT_BENEFIT)
            setOpenPopup(SHARE_PRODUCT_BENEFIT)
          }}
        />
        <TVDemo
          t={t}
          show={openPopup === TV_DEMO_START}
          onClose={() => {
            setOpenPopup(NONE)
          }}
          onClick={(code) => {
            // if (code === 'the_magnetic_cleanser_demo') {
            // }
            // if (code === 'the_pierced_agar_demo') {
            // }
            // if (code === 'the_foaming_demo') {
            // }
            // if (code === 'the_ph_demo') {
            // }
            if (code === 'get_samples') {
              setOpenPopup(GET_SAMPLES)
            }
            if (code === 'share') {
              setShareBackCode(openPopup)
              setOpenPopup(SHARE_MAGNETIC_EXPERIENCE)
            }
          }}
          showShareButton={true}
          onShareClick={() => {
            setShareBackCode(openPopup)
            setOpenPopup(SHARE_MAGNETIC_EXPERIENCE)
          }}
        />
        <PowerOfMagnetic
          t={t}
          show={openPopup === POWER_OF_MAGNETIC}
          showShareButton={false}
          onBack={() => setOpenPopup(LANDING_POP_UP)}
          onClose={() => {
            setOpenPopup(NONE)
          }}
        ></PowerOfMagnetic>
        <GetSamples
          t={t}
          show={openPopup === GET_SAMPLES}
          onClose={() => {
            setOpenPopup(NONE)
          }}
          onClick={() => {}}
        />
        <SharePopup
          t={t}
          shareUrl={STAGING_URL}
          type={'product-benefit'}
          show={openPopup === SHARE_PRODUCT_BENEFIT}
          onClose={() => setOpenPopup(NONE)}
          onClick={() => {}}
          onBack={() => {
            setOpenPopup(shareBackCode)
          }}
        />
        <SharePopup
          t={t}
          shareUrl={STAGING_URL}
          type={'share-seeing-is-beliving'}
          show={openPopup === SHARE_SEEING_AND_BELIVING}
          onClose={() => {
            setOpenPopup(NONE)
          }}
          onClick={() => {}}
          onBack={() => {
            setOpenPopup(shareBackCode)
          }}
        />
        <SharePopup
          type={'share-the-experience'}
          shareUrl={STAGING_URL}
          show={openPopup === SHARE_MAGNETIC_EXPERIENCE}
          onClose={() => {
            setOpenPopup(NONE)
          }}
          t={t}
          onClick={() => {}}
          onBack={() => {
            setOpenPopup(shareBackCode)
          }}
        />
      </ImageBackground>
    </div>
  )
}

export default withTranslation()(TestingZone)
