/**
 * Jack Dao
 */
import React from 'react'
import './LandingPopup.scss'
import { Button, Popup } from '../../components'

import magnetic_cleansing_icon from '../../images/TestingZone/magnetic_cleansing_icon.png'
import neutrogena_logo from '../../images/neutrogena_logo_white.png'
import understanding_reactive_skin from '../../images/TestingZone/understanding_reactive_skin.png'

const LandingPopup = ({
  t,
  show,
  onClose,
  onClick,
  showShareButton,
  onShareClick
}) => {
  function breakLine(text) {
    var br = React.createElement('br')
    var regex = /(<br\/>)/g
    return text.split(regex).map(function (line, index) {
      return line.match(regex) ? <br key={'key_' + index} /> : line
    })
  }
  const renderLargeButton = (icon, title, code, className = null) => {
    return (
      <div
        className={`main-button button ${className ? className : ''}`}
        onClick={() => {
          if (onClick) {
            onClick(code)
          }
        }}
      >
        <img className='img-icon' src={icon} alt='' />
        <div
          className='button-title text-center bold-text'
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    )
  }
  return (
    <Popup
      isOpen={show}
      onClose={onClose}
      showShareButton={showShareButton}
      onShareClick={onShareClick}
    >
      <div className='landing-popup'>
        <div className='row'>
          <div
            className={`main-button button the_start_of_neutrogena`}
            onClick={() => {
              if (onClick) {
                onClick('the_start_of_neutrogena')
              }
            }}
          >
            <img className='img-icon' src={neutrogena_logo} alt='' />
            <div
              className='button-title text-center bold-text'
              dangerouslySetInnerHTML={{ __html: t('the_start_of_neutrogena') }}
            />
          </div>
          {renderLargeButton(
            magnetic_cleansing_icon,
            t('the_benefits_of_magnetic_cleansing'),
            'magnetic_cleansing',
            'ml33 mb53'
          )}
        </div>
        <div className='row'>
          {renderLargeButton(
            understanding_reactive_skin,
            t('understanding_reactive_skin'),
            'understanding_reactive_skin',
            'understanding_reactive_skin'
          )}
          {renderLargeButton(
            magnetic_cleansing_icon,
            t('power_of_magnetic_cleansing'),
            'power_of_magnetic_cleansing',
            'ml33'
          )}
        </div>
      </div>
    </Popup>
  )
}

export default LandingPopup
