import React from 'react'
import './styles.scss'
import right_arrow from '../../images/right-arrow.svg'
function Button({
  title,
  style,
  className,
  onClick,
  isActive = false,
  showRightIcon = true,
  showLeftIcon = false,
  leftIcon
}) {
  return (
    <div
      onClick={onClick}
      style={style}
      className={`main-button ${isActive ? 'active' : ''} ${className} ${
        showRightIcon ? 'justify-space-between' : ''
      }`}
    >
      {showLeftIcon && <img className='button-icon' src={leftIcon} alt='' />}
      <div className='button-title'>{title}</div>
      {showRightIcon && (
        <img className='button-icon' src={right_arrow} alt='' />
      )}
    </div>
  )
}

export default Button
