/**
 * Jack Dao
 */
import React, { useState } from 'react'
import './styles.scss'
import { Button, Popup } from '../../components'
import { STAGING_URL_API, arrayYearForm } from '../../Config'

import double_sticker from '../../images/double_sticker.png'
import arrow_down from '../../images/arrow_down.png'
import axios from 'axios'

const LeavingSoSoon = ({ t, show, onClose, onClick }) => {
  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [email, setEmail] = useState(null)
  const [errorEmail, setErrorEmail] = useState(null)
  const [errorYear, setErrorYear] = useState(null)
  const [errorMonth, setErrorMonth] = useState(null)
  const [showSuccess, setShowSuccess] = useState(false)
  function breakLine(text) {
    var br = React.createElement('br')
    var regex = /(<br\/>)/g
    return text.split(regex).map(function (line, index) {
      return line.match(regex) ? <br key={'key_' + index} /> : line
    })
  }
  let currentYear = new Date().getFullYear()
  let years = arrayYearForm(currentYear)

  const registerAction = () => {
    let errorBlank = false
    if (!email) {
      setErrorEmail(t('email_can_not_blank'))
      errorBlank = true
    }
    if (!year) {
      setErrorYear(t('year_can_not_blank'))
      errorBlank = true
    }
    if (!month) {
      setErrorMonth(t('month_can_not_blank'))
      errorBlank = true
    }
    if (errorBlank) {
      return
    }
    // let opts = {
    //   'shopper[email]': email,
    //   'shopper[month_of_birth]': month,
    //   'shopper[year_of_birth]': year
    // }
    let data = {
      shopper: {
        email: email,
        month_of_birth: month,
        year_of_birth: year
      }
    }

    axios
      .post(`${STAGING_URL_API}/shoppers/create`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(
        (res) => {
          if (res.data.success) {
            // alert(t('thank_you_for_registration'))
            setShowSuccess(true)
          } else {
            let errors = res.data.errors
            let emailError = errors.email.join(', ')
            if (emailError === 'is invalid') {
              setErrorEmail(t('is_invalid'))
            } //is invalid
            if (emailError === 'has already been taken') {
              setErrorEmail(t('has_already_been_taken'))
            }
            //has already been taken
            setErrorMonth(errors.month_of_birth)
            setErrorYear(errors.year_of_birth)
          }

          // onClose()
        },
        (error) => {
          if (error && error.response) {
            let errors = error.response.data.errors
            let emailError = errors.email.join(', ')
            if (emailError === 'is invalid') {
              setErrorEmail(t('is_invalid'))
            } //is invalid
            if (emailError === 'has already been taken') {
              setErrorEmail(t('has_already_been_taken'))
            }
            //has already been taken
            setErrorMonth(errors.month_of_birth)
            setErrorYear(errors.year_of_birth)
          } else {
            // if request fail
            alert('Request fail ', error)
          }
        }
      )
  }
  return (
    <div className='' style={{ opacity: show ? 1 : 0, zIndex: show ? 10 : -2 }}>
      <Popup
        rootStyle={{ top: show ? 0 : -20000 }}
        containerStyle={
          showSuccess
            ? {
                width: '40%',
                height: '30%'
              }
            : { padding: '6% 4% 8% 4%' }
        }
        isOpen={true}
        onClose={() => {
          setEmail(null)
          setMonth(null)
          setYear(null)
          setErrorEmail(null)
          setErrorMonth(null)
          setErrorYear(null)
          setShowSuccess(false)
          onClose()
        }}
      >
        {showSuccess && (
          <div className='leaving-so-soon' style={{ height: '90%' }}>
            <div>{t('thank_you_for_registration')}</div>
          </div>
        )}
        {!showSuccess && (
          <div className='leaving-so-soon'>
            <div className='head-title'>{t('leaving_so_soon_title')}</div>
            <div className='bold-text title'>
              {breakLine(t('leaving_so_soon_des'))}
            </div>
            <img src={double_sticker} className='double-sticker' alt='' />

            <div className='row mb108'>
              <div className='input-group'>
                <div className='title-input'>{t('email_address')}</div>
                <input
                  className='custom-input'
                  type='text'
                  onChange={(e) => {
                    setEmail(e.target.value)
                    setErrorEmail(null)
                  }}
                />
                <div
                  className='error-text'
                  style={{ opacity: errorEmail ? 1 : 0 }}
                >
                  {errorEmail}
                </div>
              </div>
              <div className='mf42'>
                <div className='title-input'>{t('date_of_birth')}</div>
                <div className='row'>
                  {/* <div className='drop-down-button' onClick={() => {}}>
                <div>{t('month')}</div>
                <img className='arrow-down-icon' src={arrow_down} alt='' />{' '}
              </div> */}
                  <div>
                    <div className='select-style'>
                      <select
                        onChange={(e) => {
                          setMonth(e.target.value)
                          setErrorMonth(null)
                        }}
                      >
                        <option value='0'>{t('month')}</option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                        <option value='10'>10</option>
                        <option value='11'>11</option>
                        <option value='12'>12</option>
                      </select>
                      <img
                        className='arrow-down-icon'
                        src={arrow_down}
                        alt=''
                      />
                    </div>
                    <div
                      className='error-text'
                      style={{ opacity: errorMonth ? 1 : 0 }}
                    >
                      {errorMonth}
                    </div>
                  </div>
                  <div>
                    <div className='select-style'>
                      <select
                        onChange={(e) => {
                          setYear(e.target.value)
                          setErrorYear(null)
                        }}
                      >
                        <option value='0'>{t('year')}</option>
                        {years.map((item) => {
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          )
                        })}
                      </select>
                      <img
                        className='arrow-down-icon'
                        src={arrow_down}
                        alt=''
                      />
                    </div>
                    <div
                      className='error-text'
                      style={{ opacity: errorYear ? 1 : 0 }}
                    >
                      {errorYear}
                    </div>
                  </div>

                  {/* <div className='drop-down-button' onClick={() => {}}>
                <div>{t('year')}</div>
                <img className='arrow-down-icon' src={arrow_down} alt='' />{' '}
              </div> */}
                </div>
              </div>
            </div>
            <Button
              className='register-button'
              title={t('register')}
              showRightIcon={true}
              onClick={() => {
                registerAction()
              }}
            />
            <div className='sub-title'>
              {t('learn_more_about_our_emails_and')}
              <span
                className='underline'
                onClick={() => {
                  window.open(t('privacy_policy_url'), '_blank')
                }}
              >
                {t('privacy_policy')}
              </span>
            </div>
          </div>
        )}
      </Popup>
    </div>
  )
}

export default LeavingSoSoon
