import React from 'react'
import { Button, Popup } from '../../components'
import './PowerOfMagnetic.scss'
import skin_surface from '../../images/TestingZone/power_of_magnetic/skin_surface.png'
import understanding_magnetic_cleansing from '../../images/understanding_magnetic_cleansing.png'
import super_magnetic_acid_1 from '../../images/TestingZone/power_of_magnetic/super_magnetic_acid_1.jpg'
import super_magnetic_acid_2 from '../../images/TestingZone/power_of_magnetic/super_magnetic_acid_2.jpg'
import super_magnetic_acid_3 from '../../images/TestingZone/power_of_magnetic/super_magnetic_acid_3.jpg'
import surfactant_pentation from '../../images/TestingZone/power_of_magnetic/surfactant_pentration.png'
import cleanser_irritation_potential from '../../images/TestingZone/power_of_magnetic/cleanser_irritation_potential.png'

import clear_cleanser from '../../images/TestingZone/power_of_magnetic/clear_cleanser.png'
import clear_make_up_remover from '../../images/TestingZone/power_of_magnetic/clear_make_up_remover.png'

import hydrate_cleanser from '../../images/TestingZone/power_of_magnetic/hydrate_cleanser.png'
import hydrate_make_up_remover from '../../images/TestingZone/power_of_magnetic/hydrate_make_up_remover.png'
function PowerOfMagnetic({
  t,
  show,
  onClose,
  onBack,
  showShareButton,
  onShareClick
}) {
  return (
    <Popup
      customClass='power-of-magnetic-popup'
      containerStyle={{ padding: '4%' }}
      isOpen={show}
      onClose={onClose}
      showShareButton={showShareButton}
      onShareClick={onShareClick}
    >
      <div className='power-of-magnetic'>
        <div className='healthy-beautiful-skin'>
          <div className='text-part'>
            <div className='bold-text'>Cleansing is the first step to</div>
            <div className='head-title'>
              Healthy, <br /> Beautiful Skin
            </div>
            <div className='normal-text'>
              But over 90% of women experience <br /> either skin irritation,
              redness or <br /> dryness after cleansing<sup>12</sup>.
              <span className='bold-text'> WHY</span> ?
            </div>
          </div>
          <div className='img-part'>
            <img src={skin_surface} alt='' />
          </div>
        </div>

        <div className='super-magnetic-acid'>
          <div className='super-magnetic-acid-info'>
            <div className='head-title'>Neutrogena Labs</div>
            <div className='bold-text'>
              a winning cleansing formulation co-created with <br />{' '}
              dermatologist and backed by science.
            </div>
            <div className='normal-text'>
              OUR FIRST-EVER CLEANSING LINE WITH{' '}
              <span className='bold-text'>
                0% IRRITATION CONCERN<sup>1</sup>
              </span>
            </div>

            <div className='powered-by'>
              <span>Powered by </span>
              <img src={understanding_magnetic_cleansing} alt='' />
            </div>

            <div className='head-title super-magnetic'>
              Super&nbsp;&nbsp;Magnetic&nbsp;&nbsp;Acid
            </div>
          </div>
          <div className='super-magnetic-acid-img'>
            <img src={super_magnetic_acid_1} alt='' />
            <img src={super_magnetic_acid_3} alt='' />
            <img src={super_magnetic_acid_2} alt='' />
          </div>
        </div>

        <div className='gentle-cleansing'>
          <div className='gentle-cleansing-header'>
            <div className='bold-text'>Proof of</div>
            <div className='head-title'>
              Gentle Cleansing<sup>16</sup>
            </div>
            <div className='bold-text'>
              Our highest standard of gentle cleansing yet!
            </div>
          </div>

          <div className='gentle-cleansing-chart-group'>
            <div className='gentle-cleansing-chart'>
              <div className='reduction-info'>
                <div className='head-title'>34%</div>
                <div className='bold-text'>
                  REDUCTION IN <br />
                  SKIN PENETRATION
                </div>
              </div>
              <div className='chart-title bold-text'>SURFACTANT PENTRATION</div>
              <div className='chart-img'>
                <img src={surfactant_pentation} alt='' />
              </div>
            </div>
            <div className='gentle-cleansing-chart'>
              <div className='reduction-info'>
                <div className='head-title'>43%</div>
                <div className='bold-text'>
                  REDUCTION IN <br />
                  SKIN IRRITATION
                </div>
              </div>
              <div className='chart-title bold-text'>
                CLEANSER IRRITATION POTENTIAL
              </div>
              <div className='chart-img'>
                <img src={cleanser_irritation_potential} alt='' />
              </div>
            </div>
          </div>
        </div>

        <div className='full-range'>
          <div className='full-range-header'>
            <div className='bold-text'>Meet the</div>
            <div className='head-title'>Full&nbsp;&nbsp;Range</div>
            <div className='bold-text created-to'>
              Created to address unique reactive skin needs, formulated with
              skin-loving, <br /> derm-grade ingredients.
            </div>
          </div>

          <div className='reactive-skin dry'>
            <div className='reactive-skin-title'>
              <div className='bold-text'>For</div>
              <div className='head-title'>&nbsp;&nbsp;Dry&nbsp;&nbsp;</div>
              <div className='bold-text'>& Reactive Skin</div>
            </div>
            <div className='reactive-skin-img'>
              <img src={hydrate_make_up_remover} alt='' />
              <img src={hydrate_cleanser} alt='' />
            </div>
          </div>

          <div className='reactive-skin oily'>
            <div className='reactive-skin-title'>
              <div className='bold-text'>For</div>
              <div className='head-title'>&nbsp;&nbsp;Oily&nbsp;&nbsp;</div>
              <div className='bold-text'>& Reactive Skin</div>
            </div>
            <div className='reactive-skin-img'>
              <img src={clear_make_up_remover} alt='' />
              <img src={clear_cleanser} alt='' />
            </div>
          </div>
        </div>

        <div className='note-section'>
          <div className='note-text'>
            <sup>1</sup>Individual results may vary <sup>12</sup> Reference:
            Quilt.AI big data analysis of over 97,000 consumers <sup>13</sup>
            Walters RM, et al. Cosmetics & Toiletries. 2008; 123:53-60{' '}
            <sup>14</sup> 3X larger than normal surfactants <sup>16</sup>
            Neutrogena presentation during American Academy of Dermatology 76th
            Annual Meeting February 16-20, 2018
          </div>
          <Button onClick={onBack} title={t('back')} />
        </div>
      </div>
    </Popup>
  )
}

export default PowerOfMagnetic
